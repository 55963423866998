
import { getApp, initializeApp } from 'firebase/app';
import { getAuth, FacebookAuthProvider, GoogleAuthProvider} from '@firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage, } from '@firebase/storage';
import { environment } from '../features/referenceData/appLists';
import App from '../App';

export const isRunningLocal:boolean = false;
const isTestEnv = true;

const envToUse = environment[isTestEnv?1:2] // CHANGE THIS VALUE TO 0,1 OR 2 TO SETUP FIREBASE CONFIG IN DESIRED ENV

const firebaseConfig = 
    envToUse===environment[0] ? //0: FirebaseSandboxOneMartian (usethis for dev in OM sandbox)
    {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    }
    : envToUse===environment[1] ? //1:FirebaseDevEnviosHappy (use this for dev post Ramon Gama)
    {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY_DEV,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_DEV,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_DEV,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_DEV,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_DEV,
        appId: process.env.REACT_APP_FIREBASE_APP_ID_DEV,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_DEV
    }
    : envToUse===environment[2] ? //2: FirebaseProdEnviosHappy (use this for MTP in EH firebase)
    {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY_PROD,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_PROD,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_PROD,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_PROD,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_PROD,
        appId: process.env.REACT_APP_FIREBASE_APP_ID_PROD,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_PROD
    }:
    { //fallback to sandbox
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    }


const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp)
export const storage = getStorage(firebaseApp);
export const firestore = getFirestore(firebaseApp);
export const app = getApp()
// export const storageRef = ref;
export const facebookProvider = new FacebookAuthProvider();
export const googleProvider = new GoogleAuthProvider();
export const firebaseConfigExport = firebaseConfig;
export default firebaseApp;